/* Interface show/hide */

.hide-down {
  animation-duration: var(--interface-animation-duration);
  animation-timing-function: ease-in;
  animation-name: hide-down;
  animation-fill-mode: forwards;
}

.show-down {
  animation-duration: var(--interface-animation-duration);
  animation-timing-function: ease-in;
  animation-name: show-down;
  animation-fill-mode: forwards;
}

.hide-up {
  animation-duration: var(--interface-animation-duration);
  animation-timing-function: ease-in;
  animation-name: hide-up;
  animation-fill-mode: forwards;
}

.show-up {
  animation-duration: var(--interface-animation-duration);
  animation-timing-function: ease-in;
  animation-name: show-up;
  animation-fill-mode: forwards;
}

.hide-message-sidebar {
  animation-duration: var(--interface-animation-duration);
  animation-timing-function: ease-in;
  animation-name: hide-message-sidebar;
  animation-fill-mode: forwards;
}

.show-message-sidebar {
  animation-duration: var(--interface-animation-duration);
  animation-timing-function: ease-in;
  animation-name: show-message-sidebar;
  animation-fill-mode: forwards;
}

.hide-right {
  animation-duration: var(--interface-animation-duration);
  animation-timing-function: ease-in;
  animation-name: hide-right;
  animation-fill-mode: forwards;
}

.show-right {
  animation-duration: var(--interface-animation-duration);
  animation-timing-function: ease-in;
  animation-name: show-right;
  animation-fill-mode: forwards;
}

@keyframes hide-down {
  100% {
    bottom: -80px;
  }
}

@keyframes show-down {
  0% {
    bottom: -80px;
  }
}

@keyframes hide-up {
  100% {
    top: -80px;
  }
}

@keyframes show-up {
  0% {
    top: -80px;
  }
}

@keyframes hide-message-sidebar {
  100% {
    margin-left: -80px;
  }
}

@keyframes show-message-sidebar {
  0% {
    margin-left: -80px;
  }
}

@keyframes hide-right {
  100% {
    right: -160px;
  }
}

@keyframes show-right {
  0% {
    right: -160px;
  }
}
